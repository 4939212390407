import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Typography, Button, Toolbar,FormLabel,FormControl} from '@material-ui/core';
import { Tabs, Tab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import FormGroup from '@material-ui/core/FormGroup';



// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/third_party/ionicons.min.js';


// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'froala-editor/css/third_party/font_awesome.min.css';
import 'froala-editor/css/third_party/ionicons.min.css';

import FroalaEditor from 'react-froala-wysiwyg';

import TabContainer from './shares/react_tab_container';
import language from '../layout/language';
import ImageControl from './shares/react_image_control';
import ReactAutoSuggest from './shares/react_auto_suggest';
import ReactLazyCardMedia from './shares/react_lazy_card';

import classNames from 'classnames';
import Utils from '../api/api';
import {styles} from '../layout/theme';


class TestimonialDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: [],
      students: [],
      isFromStudent: false,
      testimonial: {
        titleVN:'',
        titleEN:'',
        url:'',
        //titleKR:'',
        commentVN:'',
        commentEN:'',
        detailVN:'',
        detailEN:'',
        //commentKR:'',
        name:'',
        avatar: '',
        classId: ''
      },
      error: {
        vn: false,
        en: false,
        //kr: false,
        name_error: '',
        titleVN_error: false,
        titleEN_error: false,
        //titleKR_error: false,
        commentVN_error:false,
        commentEN_error:false,
        //commentKR_error:false,
      },
      failed: false,
      message: '',
      isUpdate: false,
      tab: 0,
      open: false,
      options: {'empty':'empty'}
    }
  }
  componentDidMount() {
    var $this = this;
    var params = this.context.router.route.match.params;

    Utils.getBlockContents(function(options){
      $this.setState({options:options});
    });

    Utils.getListData("schedules",1, 10000, "0,0",JSON.stringify({status:1}), 1, function(data) {
      //console.log(data);
      if (data.status === "success" && data.results.length) {
        let arr = [];
        data.results.map(x=>{
          if (x.students && x.students.length){
            arr.push(x);
          }
        });
        $this.setState({ classes: arr });
      }
    });

    if (params.id !== undefined) {
      $this.setState({
        isUpdate: true
      }, function() {
        Utils.getSingleData('testimonials',params.id, function(res) {
          if (res.status === 'success' && res.results!==undefined) {
            var data = {}, item = res.results;
            for(var property in item) {
              if(item.hasOwnProperty(property)) {
                data[property] = item[property];
              }
            }
            data._id = item._id;
            if (data.url === undefined) data.url = "";
            $this.setState({testimonial: data},function(){
              //console.log($this.state.testimonial);
            });
          }
        });
      });
    }
  }
  onEditorStateChange(field,value){
    var testimonial = this.state.testimonial,
        error = this.state.error;
    error[field+'_error'] = value=='';
    testimonial[field] = value;

    this.setState({testimonial:testimonial,error:error});
  }
  handleFieldChange(e) {
    var testimonial = this.state.testimonial;
    testimonial[e.target.id] = e.target.value;
    this.setState({testimonial:testimonial});
  }
  handleValidate = flag => e => {
    var $this = this,
        testimonial = this.state.testimonial,
        obj = this.state.error;

    testimonial[e.target.id] = e.target.value;
    //console.log(e.target.id);
    var p = e.target.id + '_error';
    //console.log(p);
    if (e.target.value.trim() === '' || e.target.value === null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;

    this.setState({testimonial: testimonial, error:obj},function(){
      var url = Utils.getFriendlyURL(testimonial.name.trim() + '-' + testimonial.titleVN.trim());
      testimonial.url = url;
      $this.setState({testimonial: testimonial});
    });
  }
  selectImg(value){
    var $this = this,
        testimonial = this.state.testimonial;
    testimonial.avatar = value;
    this.setState({testimonial:testimonial},function(){
      $this.handleClose();
    });
  }
  _save(){
    var $this = this;
    var data = this.state.testimonial;
    //console.log(data);
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update('testimonial',data, function(res) {
        //console.log(res);
        if (res.status === 'success') {
          $this.context.router.history.push('/testimonial/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('testimonial',data, function(res) {
        if (res.status === 'success') {
          $this.context.router.history.push('/testimonial/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e,flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch(flag){
        case 'vn':
          obj['titleVN_error'] = this.state.testimonial.titleVN === '';
          obj['commentVN_error'] = this.state.testimonial.commentVN === '';
          obj['name_error'] = this.state.testimonial.name === '';
          obj['vn'] = obj['titleVN_error']||obj['commentVN_error']||obj['name_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        case 'en':
          obj['titleEN_error'] = this.state.testimonial.titleEN === '';
          obj['commentEN_error'] = this.state.testimonial.commentEN === '';
          obj['en'] = obj['titleEN_error']||obj['commentEN_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        default:
          return true;
      }
    }
  }
  handleCancel() {
    this.context.router.history.push('/testimonial/');
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab:value });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  onUpdateSchedule(value) {

    const $this = this;
    var testimonial = $this.state.testimonial;

    testimonial.classId = value;

    this.setState({testimonial:testimonial},function(){
      if(testimonial.classId!=='') {

        Utils.getSingleData('schedules',testimonial.classId, (res) => {
          if (res.status === 'success' && res.results!==undefined) {
            //console.log(res);
            if(res.results.students.length) {
              var arr = [];
              res.results.students.map((x)=>{
                arr.push(x);
              });
              if(arr.length) {
                Utils.getData('account_byids',arr.join(),function(data){
                  if(data.status==='success'){
                    $this.setState({students:data.results});
                  }
                });
              }
            }
          }
        });
      }
    });

  }
  onUpdateStudents(value,title) {
    const $this = this;
    var testimonial = $this.state.testimonial;

    $this.state.students.map(x=>{
      if(x._id === value) testimonial.avatar = x.avatar;
    });

    testimonial.name = title;
    this.setState({testimonial:testimonial});

  }
  render() {
    const $this = this;
    const classes = this.props.classes;
    const value = this.state.tab;
    const testimonial = this.state.testimonial;

    const editorConfigBasic = Utils.setupBasicEditor();
    const editorConfig = Utils.setupEditor('others',this.state.options);

    var arrScheules = [], arrStudents = [];

    $this.state.classes.map((x,i)=>{
      arrScheules.push({title:x.classnameVN,value:x._id});
    });

    $this.state.students.map((x,i)=>{
      arrStudents.push({title:x.fullname,value:x._id});
    });

    console.log($this.state.testimonial.classId);
    console.log($this.state.students.length);


    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Thêm/Sửa nhận xét</Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
            {/* <Tab label="한국인"/> */}
          </Tabs>
        </AppBar>
        <form className={classes.form}>
          <FormGroup className={classes.formRowInlineTop}>
            <FormControl className={classes.col6}>
              {
                ($this.state.testimonial.classId === '' || $this.state.testimonial.classId === null) ? (
                  <React.Fragment key={0}>
                    <TextField id="name" label="Tên người nhận xét" className={classes.textField} value={testimonial.name} onChange={this.handleValidate} onBlur={this.handleValidate} margin="normal"/>
                    <ImageControl label="Hình đại diện" value={testimonial.avatar} handleChange={(value) => this.selectImg(value)}></ImageControl>
                  </React.Fragment>
                ):(
                  <React.Fragment key={1}>
                    <Typography className={classes.label}>Học viên</Typography>
                    <ReactAutoSuggest className={classes.textField} data={arrStudents} onChange={(v,t)=>this.onUpdateStudents(v,t)}></ReactAutoSuggest>
                    <FormControl className={classes.formImageControl}>
                      <ReactLazyCardMedia className={classes.fluidThumbnail} image={testimonial.avatar !== '' && testimonial.avatar !== undefined ? Utils.fixImagePath(testimonial.avatar) : Utils.fixImagePath('/data/upload/images/no_photo.png',{width:100,height:100})} title={testimonial.name} />
                    </FormControl>
                  </React.Fragment>
                )
              }
            </FormControl>
            <FormControl className={classes.col6}>
              <Typography className={classes.label}>Lớp học</Typography>
              <ReactAutoSuggest value={testimonial.classId} className={classes.textField} data={arrScheules} onChange={(v,t)=>this.onUpdateSchedule(v,t)}></ReactAutoSuggest>
            </FormControl>
          </FormGroup>
        </form>
        {value === 0 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.vn && classes.hide)}>{language.VN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="titleVN" label={language.VN.position} className={classes.textField} value={testimonial.titleVN} onChange={this.handleValidate('vn')} onBlur={this.handleValidate('vn')} required={true} error={this.state.error.titleVN_error} margin="normal"/>
            <FormLabel className={classes.label_shrink} required={true} error={this.state.error.commentVN_error}>Nhận xét</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfigBasic}
              model={this.state.testimonial.commentVN}
              onModelChange={(value)=>this.onEditorStateChange('commentVN',value)}
            />
            <FormLabel className={classes.label_shrink} >Chi tiết</FormLabel>
            {
              this.state.options['empty']!=='empty' && (
                <FroalaEditor
                  tag='textarea'
                  config={editorConfig}
                  model={this.state.testimonial.detailVN}
                  onModelChange={(value)=>this.onEditorStateChange('detailVN',value)}
                />
              )
            }
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'vn')}>{language.VN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {value === 1 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.en && classes.hide)}>{language.EN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="titleEN" label={language.EN.position} className={classes.textField} value={testimonial.titleEN} onChange={this.handleValidate('en')} onBlur={this.handleValidate('en')} required={true} error={this.state.error.titleEN_error} margin="normal"/>
            <FormLabel className={classes.label_shrink} required={true} error={this.state.error.commentEN_error}>Comment</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfigBasic}
              model={this.state.testimonial.commentEN}
              onModelChange={(value)=>this.onEditorStateChange('commentEN',value)}
            />
            <FormLabel className={classes.label_shrink} >Detail</FormLabel>
            {
              this.state.options['empty']!=='empty' && (
                <FroalaEditor
                  tag='textarea'
                  config={editorConfig}
                  model={this.state.testimonial.detailEN}
                  onModelChange={(value)=>this.onEditorStateChange('detailEN',value)}
                />
              )
            }
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.EN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'en')}>{language.EN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
      </div>
    )
  }
}

TestimonialDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
TestimonialDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(TestimonialDetail);
