import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Typography,Fab,IconButton,Toolbar,TextField} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import ReactTable from './shares/react_table';
import ReactLazyCardMedia from './shares/react_lazy_card';

import classNames from 'classnames';

import {styles} from '../layout/theme';
import Utils from '../api/api';

export class TestimonialList extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: '',
      searchClick: false
    }
  }
  handleSearch(e){
    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }
  handleAdd(){
    this.context.router.history.push('/testimonial/add');
  }
  render(){
    const classes = this.props.classes;
    //console.log(this.state.categories)
    var source = {
      thead: [{title:'Tên',value:'name'}, { title: 'Hình', value: 'photo' },{title:'Chức vụ/Công việc',value: 'title'},{title: 'Nhận xét',value: 'comment'}],
      tbody: function (n) {
        return ({
          _id: n._id,
          name: n.name,
          title: n.titleVN,
          photo: <div className={classes.thumbnailWrapper}><ReactLazyCardMedia className={classes.thumbnail} image={n.avatar !== '' && n.avatar !== undefined ? Utils.fixImagePath(n.avatar) : Utils.fixImagePath('/data/upload/images/no_photo.png',{width:100,height:60})} title={n.titleVN} /></div>,
          comment: <div dangerouslySetInnerHTML={{__html:n.commentVN}}></div>
        })
      }
    };
    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography type="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục nhận xét</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
            <FormGroup className={classes.formRowToolbar}>
              <TextField type="search" id="keyword" label="Tìm kiếm" className={classes.textField + ' form-control-no-bottom'} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value.trim()})}
              margin="normal"/>
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={(e)=>this.handleSearch(e)}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiList='testimonials' apiSingle='testimonial' apiTable='testimonials' apiDuplicate={true} router={this.context.router} dataMap={source} showCheckBox={false} keyword={this.state.keyword} isSearch={this.state.searchClick}>
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

TestimonialList.propTypes = {
  classes: PropTypes.object.isRequired,
};
TestimonialList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(TestimonialList);
